@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap);
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.admin-menu .dropdown-menu {
    font-size: inherit;
}

.admin-menu .dropdown .dropdown-toggle.nav-link {
    color: inherit;
}

.admin-menu .navbar-nav .nav-item {
    padding: 0 5px;
}

.admin-menu .navbar-nav .nav-item:hover {
    background: #ddd;
    border-radius: 3px;
}

.admin-menu .navbar-nav .nav-item.active {
    border-bottom: 2px solid #0062cc;
}
.fix-footer {
    position: fixed !important;
    height: 28px !important;
    bottom: 0 !important;
    width: 100%;
    margin: 0 !important;
    border-top: 2px solid #dee2e6 !important;
    box-shadow: 0 0.1rem 1.75rem rgb(0 0 0 / 5%);
}

.admin-root {
    background-color: #ffffff;
    min-height: 85vh;
}

.admin-footer {
    position: unset !important;
    bottom: 0 !important;
    padding: 1rem 0;
    background-color: #ffffff;
}

.custom-card-width {
    max-width: 18rem;
}

.logo-admin {
    height: 45px;
    width: auto;
}

.page-header h1 {
    border-bottom: 1px solid #d7d5d5;
    font-size: 20px;
    padding: 10px 0 15px;
    margin-bottom: 15px;
}

.manage-test-kit .ant-tabs-nav {
    padding-top: 15px;
}

.manage-test-kit .ant-tabs-content-holder {
    padding-top: 20px;
}

.manage-test-kit .test-kit-qr-code {
    padding: 20px 0px;
}

.manage-test-kit .test-kit-qr-code .qr-code-label {
    border-bottom: 1px solid #d7d5d5;
    padding: 10px 0;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.manage-test-kit .test-kit-qr-code .test-qr-code {
    border: 1px solid #d7d5d5;
    border-radius: 5px;
    margin: 10px 0;
    height: 202px;
    width: 202px;
}

.manage-test-kit .test-kit-qr-code .qr-code-not-available img {
    opacity: 0.2;
    padding: 10px;
}

.download-qr-code {
    width: 200px !important;
}

.cm-add-scent .ant-form-item-label {
    display: block;
}


/* test result css start */

.cm-filter-wrapper {
    margin-bottom: 1.5rem;
}

.cm-filter-wrapper .cm-search .ant-form-item {
    display: block;
    max-width: 87%;
    width: 100%;
}

.cm-filter-wrapper .cm-country-form .ant-form-item {
    margin-bottom: 0;
    padding: 0 1rem;
}

.cm-filter-wrapper .ant-form-item:first-child {
    padding-left: 0;
}

.cm-filter-wrapper .ant-form-item:last-child {
    padding-right: 0;
}

.cm-filter-wrapper .action_btn.ant-form-item {
    padding-left: 2rem;
}

.cm-filter-wrapper .ant-form-item .ant-input {
    width: 100%;
}

.cm-filter-wrapper .ant-form-item-label>label {
    margin-bottom: 1rem;
    height: auto;
}

.ant-select-dropdown {
    min-width: -webkit-fit-content !important;
    min-width: -moz-fit-content !important;
    min-width: fit-content !important;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item:first-child {
    padding-left: 1rem;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item:last-child {
    padding-right: 1rem;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item .ant-slider {
    margin: 0 6px 0;
}

.cm-test-filer-wrapper.cm-filter-wrapper .ant-form-item .reset_btn {
    height: auto;
    line-height: 0;
    padding: 5px;
    width: 100%;
}

.cm-test-filer-wrapper.cm-filter-wrapper .cm-search {
    padding: 0 15px;
}

.cm-test-filer-wrapper.cm-filter-wrapper .cm-search .ant-row .ant-row {
    padding-left: 0;
}

/* test result css end */


/*add scent item css start*/

.cm-add-scent-wrapper .card {
    border-radius: 0.8rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
}

.cm-add-scent-wrapper .card-header {
    padding: 15px;
    border-radius: 0;
}

.cm-add-scent-wrapper .card-header h4,
.cm-add-scent-wrapper .card-header .card-title {
    margin-bottom: 0;
}

.cm-add-scent-wrapper .cm-add-scent {
    padding-top: 24px;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item {
    display: block;
    padding: 0 0 0 10px;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item.col-12 {
    padding: 0 24px;
    text-align: right;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item .ant-form-item-label {
    text-align: left;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-divider-horizontal {
    margin: 0 0 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code {
    height: 100%;
    justify-content: space-between;
    padding: 0 10px 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code .test-qr-code {
    border: 1px solid #dee2e6;
    padding: 24px 0;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code .test-qr-code img {
    object-fit: contain;
}

.cm-add-scent-wrapper .cm-add-scent .ant-form .ant-upload {
    padding: 10px;
}

@media (max-width: 767px) {
    .cm-add-scent-wrapper .cm-add-scent .ant-form .test-kit-qr-code {
        padding: 0 10px 24px;
    }
    .cm-add-scent-wrapper .cm-add-scent .ant-form .ant-form-item {
        padding: 0 10px;
    }
}


/*add scent item css end*/


/*change password css start*/

.cm-country-form .ant-form-item {
    display: block;
    margin-bottom: 2.4rem;
}

.cm-country-form .ant-form-item .ant-form-item-label>label {
    height: auto;
    margin-bottom: 1rem;
}


/*change password css end*/


/*Add Organization css start*/

.cm-add-organization-wrapper .ant-form-item {
    display: block;
    padding: 0 15px;
    margin-bottom: 2rem;
}

.cm-add-organization-wrapper .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.cm-add-organization-wrapper .page_ttl {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: normal;
}

.cm-add-organization-wrapper .organization_email {
    margin: 0.5rem 0 2rem;
    align-items: center;
    padding: 0 15px;
}

.cm-add-organization-wrapper .organization_email .ant-checkbox-wrapper {
    margin-right: 0.5rem;
}

.cm-add-organization-wrapper .organization_email p {
    font-size: 1rem;
    margin-bottom: 0;
}

.cm-add-organization-wrapper .email {
    position: relative;
}

.cm-add-organization-wrapper .email .remark {
    position: absolute;
    top: 0.2rem;
    left: 20%;
    font-size: 1.2rem;
    color: #000000;
}


/*Add Organization css end*/


/* search organization wrapper css start */

.search_organization_wrapper .date_picker_wrapper {
    display: flex;
    align-items: flex-end;
    transition: all 0.5s ease-in-out;
}

.search_organization_wrapper .ant-form-item {
    margin-bottom: 0;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item {
    display: block;
    padding: 0 15px;
    width: 100%;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-label {
    display: block;
    text-align: left;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-label>label {
    height: auto;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-picker {
    width: 100%;
}

.search_organization_wrapper .date_picker_wrapper .ant-form-item .ant-form-item-explain {
    position: absolute;
    top: 100%;
}


/* .search_organization_wrapper form {
    min-height: 12.2rem;
} */

.search_organization_wrapper form .ant-radio-wrapper {
    padding-bottom: 1rem;
    display: block;
}

.search_organization_wrapper form .ant-radio-wrapper:last-child {
    padding-bottom: 0;
}


/* search organization wrapper css end */


/*admin login page css start*/

.login_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.login_wrapper .logo_wrapper {
    margin-bottom: 24px;
}

.login_wrapper .logo_wrapper .logo {
    max-height: 19rem;
    border-bottom: 0.2rem solid #746b6e;
    padding: 0 1.5rem 1.5rem;
    margin-bottom: 1.25rem;
}

.login_wrapper .logo_wrapper p {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.15;
    text-transform: uppercase;
    color: #746b6e;
    width: 70%;
    margin: auto;
}

.login_wrapper .ant-form .ant-form-item {
    display: block;
}

.login_wrapper .ant-form .ant-form-item .ant-form-item-label {
    display: block;
    text-align: left;
    margin-bottom: 0.5rem;
}

.login_wrapper .ant-form .ant-form-item .ant-form-item-label label {
    height: auto;
    margin: 0;
}


/*admin login page css end*/



@media (max-width: 575px) {
    .cm-passfail .ant-form-item-explain-error,
    .cm-dataLocation .ant-form-item-explain-error,
    .cm-MapData .ant-form-item-explain-error {
        font-size: 9px !important;
    }
    .cm-filter-wrapper .ant-form-item:first-child {
        padding-left: 1rem;
    }
    .cm-filter-wrapper .ant-form-item:last-child {
        padding-right: 1rem;
    }
    .cm-country-form .ant-form-item .ant-form-item-label>label {
        margin-bottom: 0;
    }
}


#overlay {
  background: #ffffff61;
  color: #06d5fe;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10050;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.8;
}
.cm-component-loader {
  background: #ffffff61;
  color: #06d5fe;
  opacity: 0.8;
  z-index: 2;
  left: 0;
}
.cm-input-component-loader {
  right: 25px;
  top: 35px;
  z-index: 2;
  color: #06d5fe;
}
.cm-loader-25 {
  height: 25px;
  width: 25px;
}

html {
  font-size: 10px;
}

@media (min-width: 1921px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1920px) {
  html {
    font-size: 12px;
  }
}

@media (max-width: 1679px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 10px;
  }
}

@media (max-width: 574px) {
  html {
    font-size: 9px;
  }
}

@media (max-width: 350px) {
  html {
    font-size: 7px;
  }
}

:root {
  --primary-color: #f14f9b;
  --primary-light-color: #f176ad;
  --primary-disable-color: #f68cbe;
  --secondary-color: #afdfdf;
  --secondary-light-color: #afdfe3;
  --secondary-dark-color: #38c1c0;
  --secondary-disable-color: #7dd7d7;
}

.flex-1 {
  flex: 1 1;
}

body {
  font-size: 1.6rem;
  background-color: #e2e2e2;
  font-family: 'Nunito', sans-serif;
}

main {
  min-height: 100vh;
  max-width: 100vw;
  margin: auto;
  text-align: center;
}

main .overlay {
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 0.1;
  visibility: hidden;
}

main .overlay.active {
  visibility: visible;
}

.cm-container {
  background-image: url(/static/media/main-bg.b5442dd0.png);
  /* background-color: var(--primary-color); */
  background-size: 175%;
  background-position: center;
  min-height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  line-height: 1;
  display: flex;
  flex-direction: column;
  /*startup page css start*/
  /*startup page css end*/
  /*common css start*/
  /*common css end*/
}

@media (min-width: 1921px) {
  main .cm-container .container {
    max-width: 1600px;
  }
}

main .cm-container header {
  margin: 1.25rem 0;
  border-top: 5px solid #ffffff;
  border-bottom: 5px solid #ffffff;
}

main .cm-container header.cm-bg-secondary {
  background-color: #afdfdf;
  background-color: var(--secondary-color);
}

main .cm-container header .logo {
  display: inline-block;
  margin-right: 1rem;
}

main .cm-container header .logo img {
  max-height: 8rem;
}

main .cm-container header .offcanvas {
  background-color: #afdfdf;
  background-color: var(--secondary-color);
  border: none;
}

main .cm-container header .navbar-toggler {
  border: none;
}

main .cm-container header .navbar-toggler:focus {
  box-shadow: none;
}

main .cm-container header .navbar {
  --bs-navbar-nav-link-padding-x: 1.5rem;
  --bs-navbar-nav-link-padding-y: 1.5rem;
}

main .cm-container header .navbar .navbar-nav {
  position: relative;
}

main .cm-container header .navbar .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  font-weight: 1000;
  font-size: 1.6rem;
  color: #f14f9b;
  color: var(--primary-color);
  padding: var(--bs-navbar-nav-link-padding-y) var(--bs-navbar-nav-link-padding-x);
}

main .cm-container header .navbar .navbar-nav .cm-stars {
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(90%, -50%);
          transform: translate(90%, -50%);
}

main .cm-container header .navbar .navbar-nav .cm-stars img {
  max-width: 8.5rem;
}

main .cm-container header .navbar .cm-login .nav-link {
  text-transform: uppercase;
  font-weight: 1000;
  font-size: 1.6rem;
  color: #f14f9b;
  color: var(--primary-color);
  padding: var(--bs-navbar-nav-link-padding-y) var(--bs-navbar-nav-link-padding-x);
}

@media (max-width: 991px) {
  main .cm-container header .navbar .navbar-nav .cm-stars {
    display: none;
  }
}

@media (max-width: 767px) {
  main .cm-container header {
    margin: 0.75rem 0 0.5rem;
  }

  main .cm-container header .logo img {
    max-height: 6rem;
  }
}

/*button css start*/
main .cm-container .btn {
  border-radius: 10rem;
  padding: 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  border: none;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.35);
  height: auto;
}

main .cm-container .btn.btn-primary {
  background-color: #f14f9b;
  background-color: var(--primary-color);
  color: #ffffff;
}

main .cm-container .btn.btn-primary:disabled,
main .cm-container .btn.btn-primary.disabled,
fieldset:disabled .btn.btn-primary {
  background-color: #f68cbe;
  background-color: var(--primary-disable-color);
  color: #ffffff;
  opacity: 1;
}

main .cm-container .btn.btn-secondary {
  background-color: #38c1c0;
  background-color: var(--secondary-dark-color);
  color: #ffffff;
}

main .cm-container .btn.btn-secondary:disabled,
main .cm-container .btn.btn-secondary.disabled,
fieldset:disabled .btn.btn-secondary {
  background-color: #7dd7d7;
  background-color: var(--secondary-disable-color);
  color: #ffffff;
  opacity: 1;
}

main .cm-container .btn.cm-play-btn {
  font-weight: 1000;
  text-transform: uppercase;
}

/*button css end*/

main .cm-container .cm-main-content-wrapper {
  flex: 1 1 1px;
  position: relative;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  flex: 1 1;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .tap_wrapper {
  border-radius: 100%;
  width: 100%;
  margin: auto;
  line-height: 1;
  max-width: 26rem;
  aspect-ratio: 1 / 1;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .tap_wrapper .scanner {
  max-width: 26rem;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: auto;
  aspect-ratio: 1 / 1;
  background-color: #afdfdf;
  background-color: var(--secondary-color);
  border-radius: 100%;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper.tap_wrapper .QRCodeScanner {
  min-width: 15rem;
  max-width: 100%;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .tap_wrapper .scanner video {
  object-fit: cover;
  border-radius: 100%;
  min-width: 26rem;
  max-width: 26rem;
  width: 100%;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-btn img {
  min-width: 26rem;
  max-width: 26rem;
  width: 100%;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-or {
  font-size: 4rem;
  font-weight: 1000;
  color: #ffffff;
  text-shadow: -3px 3px 3px #000000, 3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000,
    -3px 3px 3px #000000, 3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000, -3px 3px 3px #000000,
    3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000;
  text-transform: uppercase;
  margin-bottom: 0;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input input {
  border-radius: 5rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
  padding: 1.5rem 2.5rem;
  border: 8px solid #f14f9b;
  border: 8px solid var(--primary-color);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input input::-webkit-input-placeholder {
  color: #ccc;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input input::placeholder {
  color: #ccc;
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input input:focus,
main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input input:focus-visible {
  outline-color: #afdfdf;
  outline-color: var(--secondary-color);
}

main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-input h2 {
  font-size: 2.3rem;
  font-weight: 1000;
  color: #ffffff;
  text-shadow: -3px 3px 3px #000000, 3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000,
    -3px 3px 3px #000000, 3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000, -3px 3px 3px #000000,
    3px 3px 3px #000000, 3px -3px 3px #000000, -3px -3px 3px #000000;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -1px;
  width: 75%;
  margin: 1rem auto;
}

main .cm-container .cm-main-content-wrapper .scan_wrapper .tap_wrapper .scaner video {
  object-fit: cover;
}

main .cm-container .cm-main-content-wrapper .cm-left-content {
  display: flex;
  align-items: flex-end;
  width: 75%;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon {
  max-height: calc(1vh * 61);
  max-height: calc(var(--vh, 1vh) * 61);
  overflow: hidden;
  align-self: flex-end;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-pink {
  max-height: calc(1vh * 55);
  max-height: calc(var(--vh, 1vh) * 55);
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup {
  max-height: calc(1vh * 65);
  max-height: calc(var(--vh, 1vh) * 65);
  margin-top: -4%;
  margin-left: -7%;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon img {
  max-width: 60rem;
  width: 100%;
  object-fit: contain;
  object-position: right;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon {
  max-height: calc(1vh * 30);
  max-height: calc(var(--vh, 1vh) * 30);
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon img {
  max-width: 25rem;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon.cm-three-cartoon {
  max-height: calc(1vh * 35);
  max-height: calc(var(--vh, 1vh) * 35);
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon.cm-three-cartoon img {
  max-width: 32rem;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
  max-height: calc(1vh * 70);
  max-height: calc(var(--vh, 1vh) * 70);
  position: absolute;
  top: -10%;
  right: 0;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
  max-width: 65rem;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
  margin-left: -5rem;
  position: relative;
  z-index: 2;
  max-height: unset;
}

main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
  max-width: 70rem;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent {
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  padding-left: 5rem;
  z-index: 1;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three {
  padding: 0;
  justify-content: center;
  width: 65%;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three h2 {
  font-size: 3.2rem;
  font-weight: 1000;
  color: #000000;
  text-shadow: -3px 3px 3px #ffffff, 3px 3px 3px #ffffff, 3px -3px 3px #ffffff, -3px -3px 3px #ffffff,
    -3px 3px 3px #ffffff, 3px 3px 3px #ffffff, 3px -3px 3px #ffffff, -3px -3px 3px #ffffff;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -1px;
  /* width: 75%; */
  margin: 1rem auto 3rem;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three img {
  max-width: 10rem;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent a {
  padding-left: 1rem;
  padding-right: 1rem;
}

main .cm-container .cm-main-content-wrapper .cm-select-scent a img {
  max-width: 15rem;
}

main .cm-container .cm-main-content-wrapper .cm-scent-wrapper {
  flex: 1 1;
}

main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item {
  /* background-image: url('images/star.png'); */
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  overflow: hidden;
  width: 21rem;
  aspect-ratio: 1/1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  position: relative;
  transition: all 0.5s ease-in-out;
}

main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item:focus,
main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item.active,
main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item:hover {
  cursor: pointer;
}

main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item img {
  width: 90%;
  /* height: 85%; */
  max-width: 30rem;
  max-height: 30rem;
  margin: 0 auto;
  object-fit: contain;
  z-index: 2;
  object-position: bottom;
  padding: 0.5rem 0;
}

main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item h6 {
  margin: 0 auto;
  font-weight: 1000;
  font-size: 1.2rem;
  color: #ffffff;
  text-transform: uppercase;
  max-width: 8rem;
  z-index: 2;
}

@media (max-width: 1679px) {
  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item {
    width: 23rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
    margin-left: -10rem;
    /* max-height: calc(var(--vh, 1vh) * 65); */
    max-height: unset;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
    max-width: 80rem;
  }
}

@media (max-width: 1400px) {
  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item {
    width: 21rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
    margin-left: -10rem;
    /* max-height: calc(var(--vh, 1vh) * 65); */
    max-height: unset;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
    max-width: 70rem;
  }
}

@media (max-width: 1199px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon {
    max-height: calc(1vh * 28);
    max-height: calc(var(--vh, 1vh) * 28);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup {
    margin-left: -5%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup img {
    max-width: 35rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-pink img {
    max-width: 32rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon img {
    max-width: 20rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon.cm-three-cartoon img {
    max-width: 40rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
    margin-left: -8rem;
    /* max-height: calc(var(--vh, 1vh) * 50); */
    max-height: unset;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
    max-width: 60rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 53rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper {
    flex-direction: column;
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-btn img {
    max-width: 25rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent {
    padding-left: 1rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three {
    width: 75%;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three h2 {
    margin: 1rem auto 3rem 0;
    text-align: left;
    font-size: 2.8rem;
  }
}

@media (max-width: 991px) {
  main .cm-container .cm-main-content-wrapper .cm-left-content {
    flex-direction: column;
    align-items: flex-start;
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon {
    align-self: flex-start;
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon.cm-three-cartoon img {
    max-width: 32rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon img {
    max-width: 40rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    top: auto;
    bottom: -10%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 45rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
    margin: 0 auto -10%;
    max-height: unset;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup {
    margin-left: -5%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup img {
    max-width: 28rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-pink {
    max-height: calc(1vh * 36);
    max-height: calc(var(--vh, 1vh) * 36);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-pink img {
    max-width: 23rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
    max-width: unset;
    max-height: calc(1vh * 40);
    max-height: calc(var(--vh, 1vh) * 40);
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent {
    padding-left: 2rem;
    grid-row-gap: 1.2rem;
    row-gap: 1.2rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three h2 {
    margin: 1rem auto 3rem 0;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent a img {
    max-width: 12.5rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper {
    flex: none;
    margin: auto;
    width: 70%;
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item {
    width: 50%;
    max-width: calc(1vh * 35);
    max-width: calc(var(--vh, 1vh) * 35);
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .tap_wrapper {
    max-width: 22rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-btn img,
  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .tap_wrapper .scanner video {
    min-width: 22rem;
    max-width: 22rem;
  }
}

@media (max-width: 767px) {
  main .cm-container .cm-main-content-wrapper .cm-left-content {
    width: 100%;
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon {
    margin-top: calc(1vh * 20);
    margin-top: calc(var(--vh, 1vh) * 20);
    margin-left: 0;
    max-height: calc(1vh * 35);
    max-height: calc(var(--vh, 1vh) * 35);
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon img {
    max-width: 20rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon.cm-three-cartoon img {
    max-width: 28rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon {
    max-height: calc(1vh * 50);
    max-height: calc(var(--vh, 1vh) * 50);
    margin-left: auto;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-pink {
    display: none;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-cartoon-startup {
    margin-left: auto;
    margin-right: auto;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon img {
    max-width: 35rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    position: absolute;
    top: -5%;
    bottom: auto;
    max-height: calc(1vh * 65);
    max-height: calc(var(--vh, 1vh) * 65);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 42rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper {
    flex-direction: row;
    flex: none;
    width: 100%;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent a img {
    max-width: 11.25rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three {
    width: 100%;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent.cm-three h2 {
    margin: 1rem auto 3rem;
    width: 90%;
    text-align: center;
  }
}

@media (max-width: 574px) {
  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon {
    margin-top: calc(1vh * 18);
    margin-top: calc(var(--vh, 1vh) * 18);
    max-height: calc(1vh * 24);
    max-height: calc(var(--vh, 1vh) * 24);
  }

  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon.cm-three-cartoon img {
    max-width: 23rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon {
    max-height: calc(1vh * 40);
    max-height: calc(var(--vh, 1vh) * 40);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    max-height: calc(1vh * 55);
    max-height: calc(var(--vh, 1vh) * 55);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 40rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon {
    margin: 0 auto -15%;
    width: 100%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-whatdoyousmell-cartoon img {
    object-position: 25%;
    max-height: calc(1vh * 36);
    max-height: calc(var(--vh, 1vh) * 36);
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper {
    flex-direction: column;
  }

  main .cm-container .cm-main-content-wrapper .cm-scan-wrapper .cm-scan-btn img {
    max-width: 22rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper {
    padding-bottom: 2rem;
    width: 90%;
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item {
    width: 50%;
    max-width: calc(1vh * 27);
    max-width: calc(var(--vh, 1vh) * 27);
    margin: 0 auto;
    background-size: 103%;
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item img {
    max-width: calc(1vh * 30);
    max-width: calc(var(--vh, 1vh) * 30);
    max-height: calc(1vh * 30);
    max-height: calc(var(--vh, 1vh) * 30);
  }

  main .cm-container .cm-main-content-wrapper .cm-scent-wrapper .cm-scent-item h6 {
    max-width: 8.25rem;
    margin: 0.5rem auto;
    font-size: 1.3rem;
  }
}

@media (max-width: 420px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 36rem;
  }
}

@media (max-width: 361px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 33rem;
  }
}

@media (max-width: 350px) {
  main .cm-container .cm-main-content-wrapper .cm-left-content .cm-cartoon.cm-sm-cartoon {
    margin-top: calc(1vh * 17);
    margin-top: calc(var(--vh, 1vh) * 17);
    max-height: calc(1vh * 20);
    max-height: calc(var(--vh, 1vh) * 20);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon {
    max-height: calc(1vh * 30);
    max-height: calc(var(--vh, 1vh) * 30);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    top: 8%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 45rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-select-scent a img {
    max-width: 12rem;
  }
}

@media (max-width: 300px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    top: -1%;
  }  
}


@media (max-height: 680px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon {
    max-height: calc(1vh * 70);
    max-height: calc(var(--vh, 1vh) * 70);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon img {
    max-width: 38rem;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-sm-cartoon {
    max-height: calc(1vh * 50);
    max-height: calc(var(--vh, 1vh) * 50);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    max-height: calc(1vh * 80);
    max-height: calc(var(--vh, 1vh) * 80);
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon img {
    max-width: 32rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) and (min-height: 1025px) and (max-height: 1180.98px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoon.cm-ohsoclose-cartoon {
    position: unset;
  }
}

main .cm-container .cm-bottom-content {
  position: relative;
  background-image: url(/static/media/stripe-bg.15488334.png);
  background-size: cover;
  min-height: calc(1vh * 15.3);
  min-height: calc(var(--vh, 1vh) * 15.3);
  padding: 1.5rem 0;
}

main .cm-container .cm-bottom-content .cm-chain {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  max-height: 10rem;
  object-fit: cover;
  object-position: left;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

main .cm-container .cm-bottom-content .cm-star {
  max-width: 9rem;
}

main .cm-container .cm-bottom-content .cm-star.right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

main .cm-container .cm-bottom-content .cm-play-wrapper {
  padding: 3rem 3.5rem 0;
  position: relative;
  flex: 1 1;
}

main .cm-container .cm-bottom-content .cm-play-wrapper .btn-primary,
main .cm-container .cm-bottom-content .cm-play-wrapper .btn-secondary {
  font-size: 3.2rem;
  min-width: 350px;
  font-weight: 1000;
  letter-spacing: 0.1rem;
  position: relative;
  z-index: 1;
}

main .cm-container .cm-bottom-content .cm-steps-wrapper {
  padding: 0 3.5rem;
  position: relative;
  flex: 1 1;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  list-style-type: none;
  margin: -2rem 0 0;
  z-index: 1;
  max-height: 20rem;
  overflow: hidden;
}

main .cm-container .cm-bottom-content .cm-steps-wrapper li {
  width: 100%;
  padding: 0 1rem;
  max-height: calc(1vh * 17);
  max-height: calc(var(--vh, 1vh) * 17);
}

main .cm-container .cm-bottom-content .cm-steps-wrapper li img {
  width: 100%;
  max-width: 30rem;
}

@media (max-width: 1599px) {
  main .cm-container .cm-bottom-content {
    min-height: calc(1vh * 17.1);
    min-height: calc(var(--vh, 1vh) * 17.1);
  }
}

@media (max-width: 1199px) {
  main .cm-container .cm-bottom-content {
    min-height: calc(1vh * 15);
    min-height: calc(var(--vh, 1vh) * 15);
  }
}

@media (max-width: 991px) {
  main .cm-container .cm-bottom-content {
    min-height: unset;
  }

  main .cm-container .cm-bottom-content .cm-steps-wrapper {
    padding: 0 1.5rem;
  }

  main .cm-container .cm-bottom-content .cm-play-wrapper {
    padding: 3rem 2.5rem 0rem;
  }

  main .cm-container .cm-bottom-content .cm-play-wrapper .btn-primary,
  main .cm-container .cm-bottom-content .cm-play-wrapper .btn-secondary {
    font-size: 2.4rem;
    min-width: unset;
  }
}

@media (max-width: 767px) {
  main .cm-container .cm-bottom-content .cm-steps-wrapper {
    max-height: unset;
    flex-wrap: wrap;
  }

  main .cm-container .cm-bottom-content .cm-steps-wrapper li {
    flex-shrink: 0;
    max-height: unset;
  }

  main .cm-container .cm-bottom-content .cm-play-wrapper {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }
}

@media (max-width: 574px) {
  main .cm-container .cm-bottom-content .cm-star {
    max-width: 6rem;
  }

  main .cm-container .cm-bottom-content .cm-play-wrapper .btn-primary,
  main .cm-container .cm-bottom-content .cm-play-wrapper .btn-secondary {
    font-size: 2rem;
  }

  main .cm-container .cm-bottom-content .cm-steps-wrapper {
    padding: 0 0.5rem;
    margin: 3rem 0 0;
  }

  main .cm-container .cm-bottom-content .cm-steps-wrapper li img {
    max-width: unset;
  }
}

@media (max-width: 420px) {
  main .cm-container .cm-bottom-content .cm-play-wrapper .btn-primary {
    font-size: 2rem;
  }
}

main .cm-container footer {
  background-color: #afdfe3;
  background-color: var(--secondary-light-color);
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: auto;
  padding: 1rem 1.5rem;
  width: 100%;
  z-index: 9;
}

main .cm-container footer p {
  margin-bottom: 0;
  font-size: 1.4rem;
  line-height: 1.3;
}

main .cm-container .-my-35 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.cm-step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cm-step-wrapper .steps {
  padding: 0;
  margin: 1rem auto 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cm-step-wrapper .steps .step {
  list-style-type: none;
  text-align: center;
  margin: 0.5rem;
  background-color: #afdfdf;
  background-color: var(--secondary-color);
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0.3rem -0.3rem 0rem 0 #f176ad;
  box-shadow: 0.3rem -0.3rem 0rem 0 var(--primary-light-color);
}

.cm-step-wrapper .steps .step_arrow {
  list-style-type: none;
}

.cm-step-wrapper .steps .step_arrow img {
  max-width: 7rem;
  padding: 0 1rem;
}

.cm-step-wrapper .steps .step span {
  display: block;
  color: #f176ad;
  color: var(--primary-light-color);
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 600;
}

.cm-step-wrapper .steps .step span.count {
  font-size: 3.2rem;
  line-height: 0.75;
  margin-top: 0.5rem;
  font-weight: 800;
}

.cm-step-wrapper .steps .step.active {
  outline: 0.5rem solid #f14f9b;
  outline: 0.5rem solid var(--primary-color);
}

.cm-step-wrapper .steps .step.active span {
  color: #f14f9b;
  color: var(--primary-color);
}

@media (max-width: 574px) {
  .cm-step-wrapper .steps {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .cm-step-wrapper .steps .step_arrow {
    display: none;
  }
}

/*scent test page css start*/

/*Home page CSS Start*/
main .cm-container .cm-main-content-wrapper .cm-cartoons-steps {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto -8%;
  grid-gap: 2rem;
  gap: 2rem;
}

main .cm-container .cm-main-content-wrapper .cm-cartoons-steps img {
  width: 100%;
  max-width: calc(34% - 2rem);
  object-fit: contain;
}

.cm-action-arrow {
  position: fixed;
  top: 40%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 100%;
}

.cm-action-arrow span img {
  max-width: 5rem;
}

.cm-action-arrow .pre-arrow {
  position: absolute;
  left: 0;
}

.cm-action-arrow .next-arrow {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1199px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoons-steps {
    margin: 0 auto -4%;
  }
}

@media screen and (max-width: 767px) {
  main .cm-container .cm-main-content-wrapper .cm-cartoons-steps {
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 0 auto -10%;
  }

  main .cm-container .cm-main-content-wrapper .cm-cartoons-steps img {
    max-width: 75%;
    margin: auto;
  }
}

/*Home page CSS End*/

/*# sourceMappingURL=custom.css.map */
